import React from 'react';
import { Footer } from '../components/Footer';
import {Container, Row, Col, Card} from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';
import { Header } from '../components/Header';

function Contactenos () {

    let imagenBanner = process.env.REACT_APP_SERVER_IMAGE+'estaticas/baner_principal-small2.webp';

    return (
        <>
        <Container fluid="md">
        <Header/>
            <Row style={{alignItems: 'center'}}>
                <Col sm={7}>
                    <Row className="m-3 p-3 mb-0">
                        <Col sm={12}>
                            <h3>Contáctenos</h3>
                        </Col>
                        <Col sm={12}>
                            <Card>
                                <Card.Header>Sede Buga</Card.Header>
                                <Card.Body>
                                    <blockquote className="blockquote mb-0">
                                        <small>
                                            <Icon.GeoAlt/>
                                            Dirección: CALLE 7 10 50
                                            <br></br>
                                            <Icon.Telephone/>
                                            Teléfono: 2363000
                                        </small>
                                    </blockquote>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>

                    <Row className="m-3 p-3 mb-0">
                        <Col sm={12}>
                            <Card>
                                <Card.Header>Sede Palmira</Card.Header>
                                <Card.Body>
                                    <blockquote className="blockquote mb-0">
                                        <small>
                                            <Icon.GeoAlt/>
                                            Dirección: CARRERA 33A 28 24
                                            <br></br>
                                            <Icon.Telephone/>
                                            Teléfono: 2660066
                                        </small>
                                    </blockquote>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>

                    <Row className="m-3 p-3 mb-0">
                        <Col sm={12}>
                            <Card>
                                <Card.Header>Sede Tuluá</Card.Header>
                                <Card.Body>
                                    <blockquote className="blockquote mb-0">
                                        <small>
                                            <Icon.GeoAlt/>
                                            Dirección: CARRERA 40 33 21
                                            <br></br>
                                            <Icon.Telephone/>
                                            Teléfono:2339733 
                                        </small>
                                    </blockquote>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Col>
                <Col sm={5} className="m-auto m-3 p-3 ">
                    <div style={{textAlign: 'center'}}>
                        <img src={imagenBanner} style={{width:"100%", height:"100%"}} alt="product" className="img-fluid" />
                    </div>
                </Col>
            </Row>
        </Container>
        <Footer />
        </>
    );
}

export {Contactenos};