import React, { useEffect, useState } from 'react';
import { Header } from '../components/Header';
import { Footer } from '../components/Footer';
import {Container, Row, Col, Table} from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';
import axios from 'axios';
import Cookies from 'universal-cookie';

function Informes () {
    const [arrayInformes, setArrayInformes] = useState([]);
    
    const consultar = async () => {
        const cookies = new Cookies();
        const accessToken = cookies.get('accesToken');
        const user = cookies.get('user');
        const body =  {id: user.id_cliente}

        const url = process.env.REACT_APP_SERVER + '/api/v1/items/informesMarca';
        const response = await axios.post(url, body, {headers: {
            "Authorization": `Bearer ${accessToken}`
        }});
        const objDatos = response.data;
        setArrayInformes(objDatos)
    }
    useEffect(() =>{
        consultar();
    },[])
    return (
        <>
        <Header />
        <Container fluid="md">
            <h1>INFORMES</h1>
            <br/><br/>
            <div>
                <Table striped bordered hover  size="sm" style={{textAlign:'center'}}>
                    <thead>
                        <tr>
                            <th>Marca</th>
                            <th>Nombre</th>
                            <th>Ver</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            arrayInformes.map((fila, id)=>
                            <tr key={id}> 
                                <td>{fila.marca}</td>
                                <td>{fila.nombre}</td>
                                <td><a target={'_new'}href={fila.url_informe}>VER</a></td>
                            </tr>
                            )
                        }
                    </tbody>
                </Table>
            </div>
        </Container>
        <br/><br/>
        <Footer />
        </>
    );
}

export {Informes};