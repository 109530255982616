import React, { useEffect, useState} from 'react';
import ReactGa from 'react-ga';
import '../sources/styles/stylesFooter.css';
import {Container, Row, Col,Figure} from 'react-bootstrap';
import { Link} from 'react-router-dom';
import Cookies from 'universal-cookie';
import axios from 'axios';

function Footer (props) {

    let imagenInstagram = process.env.REACT_APP_SERVER_IMAGE+'estaticas/instagram.webp';
    let imagenFecebook = process.env.REACT_APP_SERVER_IMAGE+'estaticas/facebook.webp';
    let imagenYoutube = process.env.REACT_APP_SERVER_IMAGE+'estaticas/youtube.webp';
    let imagenPSE = process.env.REACT_APP_SERVER_IMAGE+'estaticas/pse2.webp'
    const manual = process.env.REACT_APP_SERVER_IMAGE+'documentos/';
    
    let cookies = new Cookies();

    const [tokenValidado, setTokenValidado] = useState(false);
    const accessToken = cookies.get('accesToken');

    const validacionToken = async () =>{
        const url = process.env.REACT_APP_SERVER + '/requireAuth/validacionToken';
        const response = await axios.get(url, {headers: {
            "Authorization": `Bearer ${accessToken}`
        }});
        const data = response.data;
        cookies.set('accesToken', data.accessToken, {path: '/'});
        const user = cookies.get('user')
        
        if(user.tipo_usuario === 'temporal'){
            setTokenValidado(false);
        }else{
            setTokenValidado(true);
        }
    }
    useEffect(() => {
        validacionToken()
        ReactGa.initialize('UA-235989534-1')
        ReactGa.pageview(window.location.pathname + window.location.search)
    }, []);
    
    return(
        
            <Container fluid className="mb-4 mt-1 pr-5 containerFooter">
                <br />
                <br />
                <Container>
                <Row >
                    <Col md={4} sm={4}>
                        <h5>INFORMACIÓN</h5>
                        <ul>
                            {/* <li>
                                <p>Preguntas Frecuentes</p>
                            </li> */}
                            <li>
                                <Link to='/terminosCondiciones' className="enlaceFooter" state={{page: '/'}}>Términos y condiciones</Link>
                            </li>
                            <li>
                                <Link target={'new'} to='/devoluciones' className="enlaceFooter" state={{page: '/'}}>Política de devolución</Link>
                            </li>
                            <li>
                                <a target={'new'} href={manual+'PL-SIG-011 V001 POLITICA PARA EL PROGRAMA DE TRANSPARENCIA Y ETICA EMPRESARIAL.pdf'} className="enlaceFooter">Política de transparencia y ética empresarial</a>
                            </li>
                            <li>
                                <a target={'new'} href={manual+'PL-SIG-010 V001 POLITICA DE SAGRILAFT - FPADM.pdf'} className="enlaceFooter">Política de SAGRILAFT / FPADM</a>
                            </li>                            
                            <li>
                                <a target={'new'} href={manual+'PL-SIG-012 V001 POLITICA DE PROTECCION DE DATOS.pdf'} className="enlaceFooter">Protección de datos</a>
                            </li>
                            <li>
                                <a target={'new'} href={manual+'FM-SIG-001 AUTORIZACION PARA EL TRATAMIENTO DE DATOS PERSONALES V.003 .pdf'} className="enlaceFooter">Autorización para el tratamiento de datos</a>
                             </li>
                            <li>
                                <a target={'new'} href={'http://consultas.ferremaster.com.co:8080/calidad/CONSULTA/HojasVida/form.php'} className="enlaceFooter">Trabaje con nosotros</a>
                            </li>
                            <li>
                                <a target={'new'} href={'http://consultas.ferremaster.com.co:8080/calidad/CONSULTA/buscar.php'} className="enlaceFooter">Consulta Proveedores</a>
                            </li>
                            <li>
                                <a target={'new'} href={'http://192.168.10.7/calidad/CONSULTA/Pagina%20Calidad/Index.php'} className="enlaceFooter">Sistema Integrado de Gestión</a>
                            </li>
                            <li> 
                                <Link to='/contactenos' className="enlaceFooter">Contáctanos</Link>
                            </li>
                            <li>
                                <Link to='/preguntasFrecuentes' className="enlaceFooter" state={{page: '/'}}>Preguntas Frecuentes</Link>
                            </li>
                            <li>
                                <a target={'new'} href={'https://www.instagram.com/ferreteria.master/?hl=es'}>
                                    <Figure.Image
                                        className='imagenRedes'
                                        alt="171x180"
                                        src={imagenInstagram}
                                        width={'12%'} height={'12%'}
                                        loading='lazy' fallback={<p>Cargando</p>}
                                    />
                                </a>                                    
                                <a target={'new'} href={'https://www.facebook.com/FerreteriaMaster1'}>
                                    <Figure.Image
                                        className='imagenRedes'
                                        alt="171x180"
                                        src={imagenFecebook}
                                        width={'12%'} height={'12%'}
                                        loading='lazy' fallback={<p>Cargando</p>}
                                    />
                                </a>
                                <a target={'new'} href={'https://youtube.com/channel/UCftQT5UaH5nuDfYlyCnKm3Q'}>
                                    <Figure.Image
                                        className='imagenRedes'
                                        alt="171x180"
                                        src={imagenYoutube}
                                        width={'12%'} height={'12%'}
                                        loading='lazy' fallback={<p>Cargando</p>}
                                    />
                                </a>
                            </li>
                        </ul>
                    </Col>
                        
                    <Col md={4} sm={4}>
                        <h5>MI CUENTA</h5>
                        <ul>
                            {
                                tokenValidado 
                                ?
                                <li>
                                    <Link to='/actualizarDatosUsuario' className="enlaceFooter" state={{page: '/'}}>Actualizar datos</Link>
                                </li>
                                :
                                <li className='mb-3'>
                                    <Link to='/registrarUsuario' className="enlaceFooter" state={{page: '/'}}>Registrarse</Link>
                                </li>
                                
                            }                            
                            
                            <li className='mb-3 zonaPago'>
                                <a className="enlaceFooter" target={'new'} href={'https://micrositios.goupagos.com.co/ferreteria-master-ma'}>Zona de Pago</a>
                                <a target={'new'} href={'https://micrositios.goupagos.com.co/ferreteria-master-ma'}>
                                    <Figure.Image
                                        className='imagenRedes'
                                        alt="171x180"
                                        src={imagenPSE}
                                        width={'15%'} height={'15%'}
                                        loading='lazy' fallback={<p>Cargando</p>}
                                    />
                                </a>
                            </li>
                        </ul>
                    </Col>


                    <Col md={4} sm={4} style={{textAlign:'center'}}>
                        <h5>FERREMASTER</h5>
                        <ul>
                            <li>
                                <p className='parrafoFooter'>
                                    BUGA<br/>
                                    Direccion: Cll 7 # 10-50 Centro<br/>
                                    Telefono: 236 3000<br/>
                                    Celular: +57 315 592 89 48<br/>
                                </p>
                            </li>
                            <li>
                                <p className='parrafoFooter'>
                                    PALMIRA<br/>
                                    Direccion: Cra 33A # 28-24<br/>
                                    Telefono: 266 0066<br/>
                                    Celular: +57 318 372 70 47<br/>
                                </p>
                            </li>
                            <li>
                                <p className='parrafoFooter'>
                                    TULUA<br/>
                                    Direccion: Cra 40 # 33-21<br/>
                                    Telefono: 2339733<br/>
                                    Celular: +57 317 638 12 10<br/>                                    
                                </p>
                            </li>
                            
                        </ul>
                    </Col>
                </Row>
                </Container>
                <Row className='rowCopyright'>
                    <Col  sm={12}>
                        <small>Copyright 2024 © Ferremaster - Todos los derechos reservados</small>
                    </Col>
                </Row>
            </Container>            
    );
}

export {Footer};