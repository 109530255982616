import React, { useLayoutEffect, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import { Route, Routes, Navigate, useLocation } from 'react-router-dom';
import Cookies from 'universal-cookie';
import axios from 'axios';
import ReactGa from 'react-ga'
import {LandingPage} from './Pages/LandingPage';
import { ProductSingle } from './Pages/ProductSingle';
import { Catalogo } from './Pages/Catalogo';
import { InicioSesion } from './Pages/InicioSesion';
import { FormularioRegistroUsuario } from './Pages/FormularioRegistroUsuario';
import { FormularioRegistroUsuarioDetal } from './Pages/FormularioRegistroUsuarioDetal';
import { RecupararCredenciales } from './Pages/RecupararCredenciales';
import { Contactenos } from './Pages/Contactenos';
import { FormularioActualizarUsuario } from './Pages/FormularioActualizarUsuario';
import { Carrito } from './Pages/Carrito';
import { EstadoPedidos } from './Pages/EstadoPedidos';
import { EstadoCartera } from './Pages/EstadoCartera';
import { ResponsePago } from './Pages/ResponsePago';
import { ProteccionDatos } from './Pages/ProteccionDatos';
import { PedidoCliente } from './Pages/PedidoCliente';
import { Cotizacion } from './Pages/Cotizacion';
import { TerminosCondiciones } from './Pages/TerminosCondiciones';
import { Devoluciones } from './Pages/Devoluciones';
import { Informes } from './Pages/Informes';
import { ResponseEmail } from './Pages/ResponseEmail';
import { Error404 } from './Pages/Error404';
import { PreguntasFrecuentes } from './Pages/PreguntasFrecuentes';

//funcion para que al recargar la pagina se desplace hacia arriba.
const ScrollTop = ({children}) => {
  const location = useLocation();
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);
  return children
} 

function App() {

  const validacionToken = async () => {

    const cookies = new Cookies();
    const accessToken = cookies.get('accesToken');
    try {
        const url = process.env.REACT_APP_SERVER + '/requireAuth/validacionToken';
        const response = await axios.get(url, {headers: {
            "Authorization": `Bearer ${accessToken}`
        }});
        const data = response.data;
        cookies.set('accesToken', data.accessToken, {path: '/'});
    }catch (err) {
        if(err.response.status === 403){
          consultarIp();
        }  
    }
}

  const consultarIp = async () => {
    try{
      const url = 'https://www.cloudflare.com/cdn-cgi/trace';
      const { data } = await axios.get(url);
      let ip = data.split('\n')
      ip = ip[2].split('=')
      ip = ip[1];
      queryUserTemporal(ip);
    }catch(err){
      try {
        const url2 = 'https://geolocation-db.com/json/';
        const { data } = await axios.get(url2);
        queryUserTemporal(data.IPv4);
      } catch (error) {
        console.log(error);
        if (error.code === "ERR_NETWORK") {
          alert('Parece que tienes una extensión o configuración del navegador que nos está bloqueando. Si puedes desactivarla, podremos mejorar tu experiencia en nuestro sitio. ¡Gracias por tu ayuda!');
        }
      }  
    }
  }

  const queryUserTemporal = async (ip) => {
    const cookies = new Cookies();
    try{
      const url =  process.env.REACT_APP_SERVER + '/api/v1/userTemporal';
      const body = {ip: ip};
      const { data } = await axios.post(url, body);
      let user = data.result[0];
      cookies.set('accesToken', data.accessToken, {path: '/'});
      cookies.set('user', user, {path: '/'});
    }catch(err){
      console.log('Ocurrió error en la consulta de usuario temporal: ' + err)
    }
  }

  useEffect(() => {
    
    validacionToken();
    ReactGa.initialize('G-B3RJQLED90')
    ReactGa.pageview(window.location.pathname + window.location.search)
    /* if(window.caches){
      caches.open('prueba1').the ( cache =>{
        cache.addAll([
          
        ])
      })
    } */
  }, [])
  
  function RequireAuth ({children, redirecTo}) {
    const cookies = new Cookies();
  
    const accessToken = cookies.get('accesToken');
    let auth = null;
  
    if(accessToken == null || accessToken === undefined){
      auth = false
    }else{
      auth = true
    }

    return auth ? children : <Navigate to ={redirecTo} />
  }

  return (
    <>
      <ScrollTop>
        <Routes>
          <Route path='/' element={<LandingPage />} />
          <Route path="/inicioSesion" element={<InicioSesion />} />
          <Route path="/restablecer" element={<RecupararCredenciales />} />
          <Route path="/contactenos" element={<Contactenos />} />
          <Route path="/registrarUsuario" element={<FormularioRegistroUsuario />} />      
          <Route path="/registrarUsuarioDetal" element={<FormularioRegistroUsuarioDetal />} />
          <Route path="/proteccionDatos" element={<ProteccionDatos />} />      
          
          <Route path="/productSingle/:item_pedido" element={
            <ProductSingle />
          } />
          <Route path="/catalogo" element={
            <RequireAuth redirecTo="/inicioSesion">
              <Catalogo />
            </RequireAuth>
          } />
          <Route path="/catalogo/:marca" element={
              <Catalogo />
          } />
          <Route path="/actualizarDatosUsuario" element={
            <RequireAuth redirecTo="/inicioSesion">
              <FormularioActualizarUsuario />
            </RequireAuth>
          } />
          <Route path="/carrito" element={
            <RequireAuth redirecTo="/inicioSesion">
              <Carrito />
            </RequireAuth>
          } />
          <Route path="/estadoPedidos" element={
            <RequireAuth redirecTo="/inicioSesion">
              <EstadoPedidos />
            </RequireAuth>
          } />
          <Route path="/estadoCartera" element={
            <RequireAuth redirecTo="/inicioSesion">
              <EstadoCartera />
            </RequireAuth>
          } />
          <Route path="/pedidoCliente" element={
            <RequireAuth redirecTo="/inicioSesion">
              <PedidoCliente />
            </RequireAuth>
          } />
          <Route path="/cotizacion" element={
            <RequireAuth redirecTo="/inicioSesion">
              <Cotizacion />
            </RequireAuth>
          } />

          <Route path="/responsePago" element={
            <RequireAuth redirecTo="/inicioSesion">
              <ResponsePago />
            </RequireAuth>
          } />
          <Route path="/terminosCondiciones" element={
            <RequireAuth redirecTo="/inicioSesion">
              <TerminosCondiciones />
            </RequireAuth>
          } />
          <Route path="/devoluciones" element={
            <RequireAuth redirecTo="/inicioSesion">
              <Devoluciones />
            </RequireAuth>
          } />
          <Route path="/informes" element={
            <RequireAuth redirecTo="/inicioSesion">
              <Informes />
            </RequireAuth>
          } />
          <Route path="/responseEmail" element={
            <RequireAuth redirecTo="/inicioSesion">
              <ResponseEmail />
            </RequireAuth>
          } />
          <Route path="/preguntasFrecuentes" element={
            <RequireAuth redirecTo="/inicioSesion">
              <PreguntasFrecuentes />
            </RequireAuth>
          } />
          <Route path="*" element={
            <Error404 />
          }/>
        </Routes>
      </ScrollTop>
    </>
  );
}

export default App;
