import React, { useEffect, useState } from 'react';
import { Footer } from '../components/Footer';
import { Container, Row, Col, Form, Button, Modal, InputGroup, Figure, Tooltip, OverlayTrigger} from 'react-bootstrap';
import axios from 'axios';
import { Link, useLocation } from 'react-router-dom';
import * as Icon from 'react-bootstrap-icons';
import '../sources/styles/stylesTexto.css';

function FormularioRegistroUsuario() {

    const location = useLocation();

    const [validated, setValidated] = useState(false);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const [mensaje, setMensaje] = useState();
    const [mensajeValidacionTelefono, setMensajeValidacionTelefono] = useState();
    const [mensajeValidacionPassword, setMensajeValidacionPassword] = useState();
    const [mensajeValidacionEmail, setMensajeValidacionEmail] = useState();
    const [mensajeValidacionNombre, setMensajeValidacionNombre] = useState();
    const [mensajeValidacionApellido, setMensajeValidacionApellido] = useState();
    const [mensajeValidacionDireccion, setMensajeValidacionDireccion] = useState();
    const [mensajeValidacionTerminos, setMensajeValidacionTerminos] = useState();
    const [patternIdentificacion, setPatternIdentificacion] = useState({
        soft: '',
        hard: '',
        name: '',
        lastname: '',
        tooltipName: 'campo solo permite letras mayusculas, letras minusculas, letras con acento'
    });
    const patterns = {
        '': { soft: '', hard: '', name: '', lastname: '', tooltipName: '' },
        'CC': { soft: '^[1-9][0-9]{0,9}$', hard: '^[1-9][0-9]{3,9}$', name: '^[a-zA-ZáéíóúüñÁÉÍÓÚÜÑ ]{0,40}$', lastname: '', tooltipName: 'campo solo permite letras mayusculas, letras minusculas, letras con acento' },
        'CE': { soft: '^[a-zA-Z1-9][a-zA-Z0-9]{0,7}$', hard: '^[a-zA-Z1-9][a-zA-Z0-9]{3,7}$', name: '^[a-zA-ZáéíóúüñÁÉÍÓÚÜÑ ]{0,40}$', lastname: '', tooltipName: 'campo solo permite letras mayusculas, letras minusculas, letras con acento' },
        'TI': { soft: '^[1-9][0-9]{0,11}$', hard: '^[1-9][0-9]{4,11}$', name: '^[a-zA-ZáéíóúüñÁÉÍÓÚÜÑ ]{0,40}$', lastname: '', tooltipName: 'campo solo permite letras mayusculas, letras minusculas, letras con acento' },
        'NIT': { soft: '^[1-9][0-9]{0,9}$', hard: '^[1-9][0-9]{6,9}$', name: '^[a-zA-Z0-9][a-zA-Z1-9 ]{0,40}$', lastname: 'ocultar', tooltipName: 'campo solo permite números, letras mayusculas, letras minusculas' },
        'RUT': { soft: '^[1-9][0-9]{0,9}$', hard: '^[1-9][0-9]{6,9}$', name: '^[a-zA-Z0-9][a-zA-Z1-9 ]{0,40}$', lastname: 'ocultar', tooltipName: 'campo solo permite números, letras mayusculas, letras minusculas' }
    };

    let imagenLogo = process.env.REACT_APP_SERVER_IMAGE + 'estaticas/logo.webp';

    const [objFormulario, setObjFormulario] = useState({
        tipo_documento: "",
        numero_identificacion: "",
        nombre: "",
        apellido_1: "",
        apellido_2: "",
        direccion: "",
        departamento: "",
        ciudad: "",
        barrio: "",
        telefono: "",
        email: "",
        terminos_condiciones: "No acepto",
        password_cliente: "",
        codigo_vendedor: "MPMA",
        condicion_de_pago: "05A",
        tipo_de_cliente: "0009",
        grupo_descuento: "0032",
        lista_de_precios: "014",
        difusion_publicitaria_cliente: "",
        tipo_cliente_pagina: "B2C",
        tipo_descuento_cliente: "Cliente B2C",
        estado_cliente: "1",
        sucursal: "001"
    });

    const [arrayDepartamentos, setArrayDepartamentos] = useState([]);
    const [arrayCiudades, setArrayCiudades] = useState([]);

    const handleSubmit = async (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (!form.checkValidity()) {
            event.preventDefault();
            event.stopPropagation();
            if (objFormulario.telefono === '') {
                setMensajeValidacionTelefono('El campo debe contener 10 dígitos');
            }
            if (objFormulario.email === '') {
                setMensajeValidacionEmail('Debe ingresar un correo valido');
            }
            if (objFormulario.nombre === '') {
                setMensajeValidacionNombre(patternIdentificacion.tooltipName);
            }
            if (objFormulario.apellido_1 === '') {
                setMensajeValidacionApellido(patternIdentificacion.tooltipName);
            }
            if (objFormulario.terminos_condiciones === 'No acepto') {
                setMensajeValidacionTerminos('Debe aceptar los términos y condiciones');
            }
            if (objFormulario.direccion === '') {
                setMensajeValidacionDireccion('campo solo permite números, letras mayusculas, letras minusculas')
            }
        } else {
            if (objFormulario.numero_identificacion < 0) {
                alert('El número de identificación no debe ser negativo')
            } else {
                if (objFormulario.numero_identificacion % 1 !== 0) {
                    alert('El número de identificación no debe llevar puntos o comas')
                } else {
                    const url = process.env.REACT_APP_SERVER + '/api/v1/customers/register';
                    const response = await axios.post(url, objFormulario);
                    const data = response.data;
                    if (data.result) {
                        setMensaje(data.message);
                        setShow(true);
                    } else {
                        setMensaje(data.message);
                        setShow(true);
                    }
                }
            }
        }

        setValidated(true);
    };

    const consultarDepartamentos = async () => {
        try {
            const url = process.env.REACT_APP_SERVER + '/api/v1/customers/departments';
            const response = await axios.get(url);
            const data = response.data;

            let arrayDepartamentoAux = [{ name: "", id: "" }];

            for (let index = 0; index < data.length; index++) {
                arrayDepartamentoAux.push({
                    name: data[index].departamento,
                    id: data[index].id
                });
            }
            setArrayDepartamentos(arrayDepartamentoAux);
        } catch (error) {
            console.log(error);
        }
    }

    const consultarCiudades = async (idDepartamento) => {
        try {
            const url = process.env.REACT_APP_SERVER + '/api/v1/customers/cities/' + idDepartamento;
            const response = await axios.get(url);
            const data = response.data;

            let arrayCiudadesAux = [{ name: "", id: "" }];

            for (let index = 0; index < data.length; index++) {
                arrayCiudadesAux.push({
                    name: data[index].f013_descripcion,
                    id: data[index].f013_id
                });
            }
            setArrayCiudades(arrayCiudadesAux);
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        consultarDepartamentos();
    }, []);

    const changeFormulario = (e) => {
        let { name, value } = e.target;
        if (name === 'terminos_condiciones') {
            if (objFormulario.terminos_condiciones === 'No acepto') {
                setObjFormulario({
                    ...objFormulario,
                    [name]: value
                });
            } else if (objFormulario.terminos_condiciones === 'Acepto') {
                setObjFormulario({
                    ...objFormulario,
                    [name]: 'No acepto'
                });
            }
            setMensajeValidacionTerminos('');
        } else if (name === 'tipo_documento') {
            setObjFormulario({
                ...objFormulario,
                numero_identificacion: '',
                nombre: '',
                [name]: value
            });
            setPatternIdentificacion(patterns[value]);
            setMensajeValidacionNombre('');
            setMensajeValidacionApellido('');
        } else {
            setObjFormulario({
                ...objFormulario,
                [name]: value
            });
        }
    }

    const validacionTelefono = (e) => {
        const { value } = e.target;
        let mensaje = '';
        let validacion = true;
        if (value.length !== 10) {
            validacion = false;
            mensaje = 'El campo debe contener 10 dígitos';
        }
        setMensajeValidacionTelefono(mensaje)

        if (!validacion) {
            document.getElementById("telefono").classList.remove("is-valid");
            document.getElementById("telefono").classList.add("is-invalid");
            document.getElementById("telefono").setCustomValidity("invalid");
        } else {
            document.getElementById("telefono").classList.remove("is-invalid");
            document.getElementById("telefono").classList.add("is-valid");
            document.getElementById("telefono").setCustomValidity("");
        }
        changeFormulario(e);
    }

    const changeEmail = (e) => {
        let validacion = true;
        const { value } = e.target;
        if (value === "") {
            validacion = false;
        }
        if (value.indexOf('@') === -1 || value.indexOf('.') === -1) {
            validacion = false;
        }
        // Verificar que el '@' esté antes del último '.'
        if (value.lastIndexOf('@') > value.lastIndexOf('.')) {
            validacion = false;
        }
        // Verificar que hay caracteres antes del '@' y después del último '.'
        if (value.indexOf('@') === 0 || value.lastIndexOf('.') === value.length - 1) {
            validacion = false;
        }
        // Verificar que haya al menos un carácter entre el '@' y el último '.'
        if (value.lastIndexOf('.') - value.indexOf('@') <= 1) {
            validacion = false;
        }
        // Verificar que el texto después del último punto tenga más de dos caracteres
        let ultimoPuntoIndex = value.lastIndexOf('.');
        let textoDespuesPunto = value.substring(ultimoPuntoIndex + 1);
        if (textoDespuesPunto.length <= 1) {
            validacion = false;
        }
        if (!validacion) {
            document.getElementById("email").classList.remove("is-valid");
            document.getElementById("email").classList.add("is-invalid");
            document.getElementById("email").setCustomValidity("invalid");
            setMensajeValidacionEmail('Debe ingresar un correo valido');
        } else {
            document.getElementById("email").classList.remove("is-invalid");
            document.getElementById("email").classList.add("is-valid");
            document.getElementById("email").setCustomValidity("");
            setMensajeValidacionEmail('');
        }
        changeFormulario(e);
    }

    const changeIdentificacion = (e) => {
        const { value } = e.target;
        if (value === "") {
            changeFormulario(e);
        } else {
            let regex = new RegExp(patternIdentificacion.soft);
            if (regex.test(value)) {
                changeFormulario(e);
            }
        }
    }

    const changeNombre = (e) => {
        const { value } = e.target;
        if (value === "") {
            changeFormulario(e);
        } else {
            let regex = new RegExp(patternIdentificacion.name);
            if (regex.test(value)) {
                changeFormulario(e);
            }
        }
        setMensajeValidacionNombre('');
        setMensajeValidacionApellido('');
    }

    const changeDepartamentos = (e) => {
        consultarCiudades(e.target.value);
        changeFormulario(e);
    }

    const changeDireccion = (e) => {
        const { value } = e.target;
        if (value === "") {
            changeFormulario(e);
        } else {
            let regex = new RegExp("^[a-zA-Z0-9 ]+$");
            if (regex.test(value)) {
                changeFormulario(e);
            }
        }
        setMensajeValidacionDireccion('');
    }

    function validacionCaracteres(texto, caracteres) {
        for (let i = 0; i < texto.length; i++) {
            if (caracteres.indexOf(texto.charAt(i), 0) !== -1) {
                return true;
            }
        }
        return false;
    }

    const blurPassword = (e) => {
        changeFormulario(e);
        let letras_minusculas = "abcdefghyjklmnñopqrstuvwxyz";
        let letras_mayusculas = "ABCDEFGHYJKLMNÑOPQRSTUVWXYZ";
        let numeros = "0123456789";
        let simbolos = "!/%#$*";

        const { value } = e.target;
        let validacion = false;
        let mensaje = "";

        if (value !== "") {
            //valida la longitud
            if (value.length < 12) {
                mensaje = "Debe de contener minímo 12 carácteres";
            } else {
                //valida letra minusculas
                if (validacionCaracteres(value, letras_minusculas)) {
                    //valida letra mayúscula
                    if (validacionCaracteres(value, letras_mayusculas)) {
                        //valida números
                        if (validacionCaracteres(value, numeros)) {
                            //valida simbolo 
                            if (validacionCaracteres(value, simbolos)) {
                                validacion = true;
                            } else {
                                mensaje = "Debe de contener al menos 1 de los siguientes carácteres especiales (!, /, %, #, $, *)";
                            }
                        } else {
                            mensaje = "Debe de contener al menos 1 número";
                        }
                    } else {
                        mensaje = "Debe de contener al menos 1 letra mayúscula";
                    }
                } else {
                    mensaje = "Debe de contener al menos 1 letra minúscula";
                }
            }
        } else {
            validacion = true;
        }

        if (!validacion) {
            document.getElementById("password_cliente").classList.remove("is-valid");
            document.getElementById("password_cliente").classList.add("is-invalid");
            document.getElementById("password_cliente").setCustomValidity("invalid");
        } else {
            document.getElementById("password_cliente").classList.remove("is-invalid");
            document.getElementById("password_cliente").classList.add("is-valid");
            document.getElementById("password_cliente").setCustomValidity("");
        }
        setMensajeValidacionPassword(mensaje);
    }

    function mostrarPassword() {
        var campo = document.getElementById("password_cliente");
        if (campo.type === "password") {
            campo.type = "text";
            document.getElementById("iconoOcultar").style = "display:none";
            document.getElementById("iconoMostrar").style = "display:block";
        } else {
            campo.type = "password";
            document.getElementById("iconoOcultar").style = "display:block";
            document.getElementById("iconoMostrar").style = "display:none";
        }
    }

    return (
        <>
            <Container fluid="md">
                <Row>
                    <Col sm={12}>
                        <Link to='/'>
                            <Figure.Image
                                className='image-logo'
                                alt="171x180"
                                src={imagenLogo}
                            />
                        </Link>
                    </Col>
                </Row>
                <Row style={{ alignItems: 'center' }}>
                    <Col sm={12}>
                        <Row className="m-3 p-3">
                            <Row>
                                <Col sm={12}>
                                    <h4>Formulario de Registro Cliente</h4>
                                </Col>
                            </Row>

                            <Row>
                                <Col md={7} className="m-auto">
                                    <label>Por favor ingresar los siguientes datos </label>
                                    <br></br>
                                    <br></br>

                                    <Form noValidate validated={validated} onSubmit={handleSubmit}>

                                        <Form.Group as={Row}>
                                            <Col sm={4}>
                                                <Form.Label>Tipo-Documento<small className="text-danger">*</small></Form.Label>
                                            </Col>
                                            <Col sm={8}>
                                                <select name='tipo_documento' className="form-control" required onChange={changeFormulario}>
                                                    <option></option>
                                                    <option value={'CC'}>CC - Cédula de ciudadanía</option>
                                                    <option value={'CE'}>CE - Cédula de extranjería</option>
                                                    <option value={'TI'}>TI - Tarjeta de identidad</option>
                                                    <option value={'NIT'}>NIT - Número de Identificación Tributaria</option>
                                                    <option value={'RUT'}>RUT - Registro único tributario</option>
                                                </select>
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row}>
                                            <Col sm={4}>
                                                <Form.Label>Identificación<small className="text-danger">*</small></Form.Label>
                                            </Col>
                                            <Col sm={8}>
                                                <Form.Control type="text" id="numero_identificacion" name="numero_identificacion" pattern={patternIdentificacion.hard} value={objFormulario.numero_identificacion} onChange={changeIdentificacion} placeholder="Identificación" required autoComplete="off" />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row}>
                                            <Col sm={4}>
                                                <Form.Label>{patternIdentificacion.lastname === '' ? 'Nombres' : 'Razón Social'}<small className="text-danger">*</small>
                                                    <OverlayTrigger placement="top" overlay={<Tooltip id="button-tooltip">{patternIdentificacion.tooltipName}</Tooltip>}>
                                                        <Button variant="outline-default"><Icon.InfoCircle /></Button>
                                                    </OverlayTrigger>
                                                </Form.Label>
                                            </Col>
                                            <Col sm={8}>
                                                <Form.Control maxLength={40} type="text" name="nombre" pattern={patternIdentificacion.name} value={objFormulario.nombre} onChange={changeNombre} placeholder={patternIdentificacion.lastname === '' ? 'Nombres' : 'Razón Social'} required autoComplete="off" />
                                                {mensajeValidacionNombre !== "" && <small className="text-danger">{mensajeValidacionNombre}</small>}
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} className={patternIdentificacion.lastname}>
                                            <Col sm={4}>
                                                <Form.Label>Apellidos<small className="text-danger">*</small></Form.Label>
                                                <OverlayTrigger placement="top" overlay={<Tooltip id="button-tooltip">{patternIdentificacion.tooltipName}</Tooltip>}>
                                                    <Button variant="outline-default"><Icon.InfoCircle /></Button>
                                                </OverlayTrigger>
                                            </Col>
                                            <Col sm={8}>
                                                <Form.Control maxLength={40} type="text" name="apellido_1" pattern={patternIdentificacion.name} value={objFormulario.apellido_1} onChange={changeNombre} placeholder="Apellidos" autoComplete="off" required={patternIdentificacion.lastname === 'ocultar' ? false : true} />
                                                {mensajeValidacionApellido !== "" && <small className="text-danger">{mensajeValidacionApellido}</small>}
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row}>
                                            <Col sm={4}><Form.Label>Departamento<small className="text-danger">*</small></Form.Label></Col>
                                            <Col sm={8}>
                                                <select name="departamento" className="form-control" required onChange={changeDepartamentos} >
                                                    {
                                                        arrayDepartamentos.map((dpto) => {
                                                            return (
                                                                <option key={dpto.id} value={dpto.id}>
                                                                    {dpto.name}
                                                                </option>
                                                            );
                                                        })
                                                    }
                                                </select>
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row}>
                                            <Col sm={4}>
                                                <Form.Label>Ciudad<small className="text-danger">*</small></Form.Label>
                                            </Col>
                                            <Col sm={8}>
                                                <select name="ciudad" className="form-control" required onChange={changeFormulario} >
                                                    {
                                                        arrayCiudades.map((dpto) => {
                                                            return (
                                                                <option key={dpto.id} value={dpto.id}>
                                                                    {dpto.name}
                                                                </option>
                                                            );
                                                        }
                                                        )}
                                                </select>
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row}>
                                            <Col sm={4}><Form.Label>Barrio</Form.Label></Col>
                                            <Col sm={8}><Form.Control type="text" name="barrio" placeholder="Barrio" onChange={changeFormulario} autoComplete="off" /></Col>
                                        </Form.Group>
                                        <Form.Group as={Row}>
                                            <Col sm={4}>
                                                <Form.Label>Dirección<small className="text-danger">*</small>
                                                <OverlayTrigger placement="top" overlay={<Tooltip id="button-tooltip">campo solo permite números, letras mayusculas, letras minusculas</Tooltip>}>
                                                    <Button variant="outline-default"><Icon.InfoCircle /></Button>
                                                </OverlayTrigger>
                                                </Form.Label>
                                            </Col>
                                            <Col sm={8}>
                                                <Form.Control type="text" name="direccion" placeholder="Dirección" required value={objFormulario.direccion} onChange={changeDireccion} pattern="^[a-zA-Z0-9 ]+$" autoComplete="off" />
                                                {mensajeValidacionDireccion !== "" && <small className="text-danger">{mensajeValidacionDireccion}</small>}
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row}>
                                            <Col sm={4}>
                                                <Form.Label>Teléfono<small className="text-danger">*</small>
                                                    <OverlayTrigger placement="top" overlay={<Tooltip id="button-tooltip">campo solo permite números</Tooltip>}>
                                                        <Button variant="outline-default"><Icon.InfoCircle /></Button>
                                                    </OverlayTrigger>
                                                </Form.Label>
                                            </Col>
                                            <Col sm={8}>
                                                <Form.Control type="number" name="telefono" id="telefono" placeholder="Teléfono" required onChange={validacionTelefono} autoComplete="off" />
                                                {mensajeValidacionTelefono !== "" && <small className="text-danger">{mensajeValidacionTelefono}</small>}
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row}>
                                            <Col sm={4}><Form.Label>Correo<small className="text-danger">*</small></Form.Label></Col>
                                            <Col sm={8}>
                                                <Form.Control type="email" id="email" name="email" placeholder="Correo" onChange={changeEmail} autoComplete="off" required />
                                                {mensajeValidacionEmail !== "" && <small className="text-danger">{mensajeValidacionEmail}</small>}
                                            </Col>

                                        </Form.Group>
                                        <Form.Group as={Row}>
                                            <Col sm={4}><Form.Label>Contraseña<small className="text-danger">*</small></Form.Label></Col>
                                            <Col sm={8}>
                                                <InputGroup>
                                                    <Form.Control required type="password" name="password_cliente" id="password_cliente" placeholder="Contraseña" onChange={blurPassword} autoComplete="off" />
                                                    <Button variant="warning" onClick={mostrarPassword}><Icon.Eye id="iconoMostrar" style={{ display: "none" }} /><Icon.EyeSlash id="iconoOcultar" /></Button>
                                                </InputGroup>
                                                {mensajeValidacionPassword !== "" && <small className="text-danger">{mensajeValidacionPassword}</small>}
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row}>
                                            <Col sm={6}>
                                                <Form.Label column>
                                                    <a target={'_new'} href={'./terminosCondiciones'}>Términos y condiciones<small className="text-danger">*</small></a>
                                                </Form.Label>
                                            </Col>
                                            <Col>
                                                <Form.Check style={{ textAlign: 'left' }} onChange={changeFormulario}
                                                    name="terminos_condiciones"
                                                    value="Acepto"
                                                    className="mb-3"
                                                    type="checkbox"
                                                    id={`default-checkbox`}
                                                    required
                                                />
                                            </Col>
                                            {mensajeValidacionTerminos !== "" && <small className="text-danger">{mensajeValidacionTerminos}</small>}
                                        </Form.Group>
                                        <br></br>
                                        <br></br>
                                        <div className="d-grid gap-2">
                                            <Button variant="outline-warning" type="submit">Guardar <Icon.Save /></Button>
                                        </div>
                                    </Form>
                                </Col>
                            </Row>
                        </Row>
                    </Col>
                </Row>

                <Modal
                    show={show}
                    onHide={handleClose}
                    backdrop="static"
                    keyboard={false}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>{mensaje}</Modal.Title>
                    </Modal.Header>
                    <Modal.Footer>
                        <Link to={location.state.page === '/carrito' ? '/carrito' : '/inicioSesion'} className="btn btn-outline-warning">Aceptar</Link>
                    </Modal.Footer>
                </Modal>
            </Container>
            <Footer />
        </>
    );
}

export { FormularioRegistroUsuario };