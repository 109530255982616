import React, { useEffect, useState, Fragment } from 'react';
import axios from 'axios';
import Cookies from 'universal-cookie';
import moment from 'moment';
import * as Icon from 'react-bootstrap-icons';
import { Header } from '../components/Header';
import { Container, Row, Col, Form, Table, Button, Modal, Alert } from "react-bootstrap";

import Swal from 'sweetalert2';
import Spinner from 'react-bootstrap/Spinner';

function PedidoCliente() {

    const [fechas, setFechas] = useState({ fecha_inicial: moment().format('YYYY-MM-DD'), fecha_final: moment().format('YYYY-MM-DD') });
    const [pedidos, setPedidos] = useState([]);
    const [itemsPedido, setItemsPedido] = useState([]);
    const [lgShow, setLgShow] = useState(false);
    const [permisoValidacion, setPermisoValidacion] = useState('none');
    const [visualizacion, setVisualizacion] = useState(false);
    const [cargaShow, setCargaShow] = useState(false);

    const formatoPesosColombianos = new Intl.NumberFormat('es-CO', {
        style: 'currency',
        currency: 'COP',
        minimumFractionDigits: 0
    });
    const formatoPorcentaje = (num) =>{
        return Number(num/100).toLocaleString(undefined,{style: 'percent', minimumFractionDigits:2});
    }
    const modalItems = <Modal
                    size="lg"
                    show={lgShow}
                    onHide={() => setLgShow(false)}
                    className="text-center"
                    aria-labelledby="example-modal-sizes-title-lg"
                >
                    <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                        Items del Pedido
                    </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Table responsive bordered>
                            <thead>
                                <tr>
                                    <th>ITEM</th>
                                    <th>DESCRIPCIÓN</th>
                                    <th>UM</th>
                                    <th>CANTIDAD</th>
                                    <th>IVA</th>
                                    <th>DESCUENTO</th>
                                    <th>VALOR FINAL</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    itemsPedido.map((item, i) => 
                                    <tr key={i}>
                                        <td>{item.item}</td>
                                        <td>{item.descripcion_item}</td>
                                        <td>{item.um}</td>
                                        <td>{Math.round(item.cantidad)}</td>
                                        <td>{formatoPorcentaje(item.iva)}</td>
                                        <td>{formatoPorcentaje(item.descuento)}</td>
                                        <td>{formatoPesosColombianos.format(Math.round(item.total))}</td>
                                    </tr>
                                    )
                                }
                            </tbody>
                        </Table>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={() => handleModalItemsCerrar()}>Close</Button>
                    </Modal.Footer>
                </Modal>
            
    const handleModalItems = async (id_pedido) => {
        const cookies = new Cookies();
        const accessToken = cookies.get('accesToken');
        setLgShow(true);
        try {
            const url = process.env.REACT_APP_SERVER + '/api/v1/pedidosItemsCatalogo/items';
            const body = {id_pedido};
            const { data } = await axios.post(url, body, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                }
            });
            setItemsPedido(data);
        } catch (error) {
            console.log('Error en la consulta de items pedidos: ' + error.message)
        }
    }
    const handleModalItemsCerrar = () => {
        setLgShow(false);
        setItemsPedido([]);
    }
                    
    const handleFechas = e => {
        const { name, value } = e.target

        if (name === 'fecha_inicial') {
            if (moment(value) > moment(fechas.fecha_final)) {
                alert('La fechas inicial no puede ser mayor a la fecha final')
            } else {
                setFechas({
                    ...fechas,
                    [name]: value
                });
            }
        } else {
            if (moment(value) < moment(fechas.fecha_inicial)) {
                alert('La fecha final no puede ser menor a la fecha inicial')
            } else {
                setFechas({
                    ...fechas,
                    [name]: value
                });
            }
        }
    }
    const handleEliminarPedido = async (id) =>{
        
        const cookies = new Cookies();
        const accessToken = cookies.get('accesToken');
        const estadoPedido = 'Denegado';
        const body = {id_pedido:id, estado: estadoPedido};
        const actualizarEstado = process.env.REACT_APP_SERVER + '/api/v1/pedido_catalogo/actualizar_estado';
        const respCliente = await axios.post(actualizarEstado,  body,
            {headers: {
            "Authorization": `Bearer ${accessToken}`
        }})

        if(respCliente.data.result){
            mostrarAlert('success', 'title', respCliente.data.menssage)    
            setTimeout(() => {
                window.location.reload();
            }, 500);       
        }
        
    }
    const handleGenerarPedido = async (id) =>{
        
        const cookies = new Cookies();
        const accessToken = cookies.get('accesToken');
        const user = cookies.get('user');
        const estadoPedido = 'Aprobado';
        const ObjBody = {id_pedido:id, estado: estadoPedido};
        setVisualizacion(false)
        setCargaShow(true)
        const actualizarEstado = process.env.REACT_APP_SERVER + '/api/v1/pedido_catalogo/actualizar_estado';
        const respCliente = await axios.post(actualizarEstado,  ObjBody,
            {headers: {
            "Authorization": `Bearer ${accessToken}`
        }})
        const objInformacion = respCliente.data.body;

        const informacionPedido = objInformacion.dataConsulta[0];
        const informacion = objInformacion.dataConsultaItems;
        
        const arrayProductos = [];
        let valor_final = 0;
        const urlCalcularCotizacion = process.env.REACT_APP_SERVER + '/api/v1/pricesCotizacion';
        for (let i = 0; i < informacion.length; i++) {
            const url = process.env.REACT_APP_SERVER + `/api/V1/productSingle/${informacion[i].item}`;
            const response = await axios.get(url);
            let datos ={
                idCliente: informacionPedido.id_cliente,
                sucursal: informacionPedido.sucursal,
                punto_envio: informacionPedido.punto_envio,
                unidad_de_medida: informacion[i].um,
                item: informacion[i].item,
                cantidad: informacion[i].cantidad
                
            }
            const { data } = await axios.post(urlCalcularCotizacion, datos);

            if(data.length === undefined){
                let total = parseFloat(((data.precio - ((data.precio * data.descuentoFinal)/ 100)) * informacion[i].cantidad)).toFixed(2);
                let subtotal = parseInt(total) / (1 + parseFloat(informacion[i].iva / 100));
                let impuesto = subtotal * parseFloat(informacion[i].iva) / 100;
                valor_final += parseFloat(total);

                arrayProductos.push({
                    item: informacion[i].item,
                    descripcion_item: response.data[0].descripcion_alternativa,
                    um: informacion[i].um,
                    cantidad: informacion[i].cantidad,
                    lista: '001',
                    precio: data.precio,
                    iva: informacion[i].iva,
                    descuento: data.descuentoFinal,                    
                    subtotal: Math.round(subtotal),
                    impuesto: Math.round(impuesto),
                    total: total
                });
                
            }

        }
        if(informacionPedido.url_soporte_pago === null){

            informacionPedido.url_soporte_pago = undefined;
            informacionPedido.codigo_soporte_pago = undefined;

        }
        const body = {
            medio_de_pago: informacionPedido.medio_de_pago, 
            medio_de_entrega:informacionPedido.medio_de_entrega, 
            items: arrayProductos, 
            codigo_soporte_pago: informacionPedido.codigo_soporte_pago,
            soportePago: informacionPedido.url_soporte_pago, 
            transporte: informacionPedido.transporte, 
            sucursal:informacionPedido.sucursal_cliente, 
            punto_envio: informacionPedido.punto_envio_cliente,
            valor_final: valor_final.toFixed(2),
            fecha_recoge: moment().format('YYYY-MM-DD'),
            departamento: informacionPedido.departamento_destino, 
            ciudad: informacionPedido.ciudad_destino, 
            direccion: informacionPedido.direccion, 
            merchantId:process.env.REACT_APP_MERCHANT_ID,
            currency:process.env.REACT_APP_CURRENCY,
            telefono: user.telefono,
            observaciones: informacionPedido.observaciones
        }
        InsertPedido(body, accessToken)        
    }
    
    const InsertPedido = async (datos, accessToken) =>{
                             
        const alertaURL = process.env.REACT_APP_SERVER + '/api/v1/pedidos/alert';  
        const { correo } = await axios.post(alertaURL, datos, {
            headers: {
                'Authorization': `Bearer ${accessToken}`
            }
        });     
        const url = process.env.REACT_APP_SERVER + '/api/v1/pedidos/register';
        const { data } = await axios.post(url, datos, {
            headers: {
                'Authorization': `Bearer ${accessToken}`
            }
        }); 

        if(data.result){
            let objPedido = {
                estado_pedido: 'Aprobado',
                usuario: 'b2b2c',
                id_pedido: data.id_pedido,
                id_cliente: data.id_cliente
            }
            const urlAprobar = process.env.REACT_APP_SERVER + '/api/v1/pedidos/edit';
            await axios.post(urlAprobar, objPedido);
        }
        
        setTimeout(() => {
            setVisualizacion(true)
        }, (1000 * datos.items.length)/2);
    } 
    const handleModalCargaCerrar = () => {
        window.location.reload();
        setCargaShow(false);
    }

    const modalCarga = <Modal
                    /* size="lg" */
                    show={cargaShow}
                    backdrop="static"
                    keyboard={false}    
                    /* onHide={() => setCargaShow(false)} */
                    className="text-center"
                    aria-labelledby="example-modal-sizes-title-lg"
                >
                    <Modal.Header >
                    <Modal.Title id="example-modal-sizes-title-lg">
                        CONVIRTIENDO PEDIDO
                    </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {
                            visualizacion === false 
                            ?
                            <Spinner animation="border" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </Spinner>
                            :
                            <a href='/estadoPedidos' target={'_blank'}onClick={() => handleModalCargaCerrar()}><strong>FINALIZADO</strong></a>
                        }
                    </Modal.Body>
                </Modal>
    const  mostrarAlert = (icono, titulo, mensaje) =>{
        Swal.fire({
            icon: icono,
            title: mensaje
        });

    }
    const queryPedidos = async (fechas2) => {
        try{
            const cookies = new Cookies();
            const accessToken = cookies.get('accesToken');
            const user = cookies.get('user');

            const urlCliente = process.env.REACT_APP_SERVER + '/api/v1/validarListaPrecio';
            const respCliente = await axios.get(urlCliente, {headers: {
                "Authorization": `Bearer ${accessToken}`
            }});
            if(respCliente.data.tipo_cliente_pagina !== 'CATALOGO'){
                setPermisoValidacion('block')
            }
            const url = process.env.REACT_APP_SERVER + '/api/v1/pedidos/pedidoClienteCatalogo'
            const { data } = await axios.post(url, {numero_documento: user.numero_identificacion}, {
                headers:{
                    'Authorization': `Bearer ${accessToken}`
                }
            });
            setPedidos(data);
        }catch(err){
            console.log("Error en la consulta de estado pedido: " + err)
        }
    }

    useEffect(() => {
        queryPedidos(fechas);
    }, [fechas])
    return (
        <>
            <Header />
            <br />
            <Container>
                <Row className="mb-4" sm={6}>
                    <Col sm={6}>
                        <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                            <Form.Label column sm="2">
                                Fecha Inicio
                            </Form.Label>
                            <Col sm="5">
                                <Form.Control max={fechas.fecha_final} name="fecha_inicial" value={fechas.fecha_inicial} type="date" onChange={handleFechas} />
                            </Col>
                        </Form.Group>
                    </Col>
                    <Col sm={6}>
                        <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                            <Form.Label column sm="2">
                                Fecha Final
                            </Form.Label>
                            <Col sm="5">
                                <Form.Control min={fechas.fecha_inicial} name="fecha_final" value={fechas.fecha_final} type="date" onChange={handleFechas} />
                            </Col>
                        </Form.Group>

                    </Col>
                </Row>
                <Row>
                {
                    pedidos.length > 0 ?
                        <Table responsive bordered className="text-center">
                            <thead>
                                <tr>
                                    <th>PEDIDO</th>
                                    <th>ITEMS</th>
                                    <th>FECHA</th>
                                    {/* <th>ESTADO PEDIDO</th> */}
                                    <th>VALOR</th>
                                    <th style={{display: permisoValidacion}}>VALIDACION</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    pedidos.map((item, i) =>
                                        <tr key={i}>
                                            <td>{item.numero_pedido}</td>
                                            <td>
                                            {
                                                <Button variant="outline-warning" onClick={() => handleModalItems(item.id_pedido)}>
                                                    <Icon.Basket2 />
                                                </Button>
                                                
                                            }
                                            {modalItems}
                                            </td>
                                            <td>{moment(item.fecha_recoger).format('YYYY-MM-DD HH:mm:ss')}</td>
                                            {/* <td>{item.estado_pedido}</td> */}
                                            <td>{formatoPesosColombianos.format(Math.round(item.valor_final))}</td>
                                            
                                            
                                            <td style={{display: permisoValidacion}}>                                                
                                                    
                                                    {
                                                        <Button variant="outline-success" title='Aprobar Pedido' onClick={() => handleGenerarPedido(item.id_pedido)} >
                                                        <Icon.CheckCircle />
                                                    </Button>
                                                    }
                                                    {modalCarga}
                                                    <Button variant="outline-danger" title='Rechazar Pedido' onClick={() => handleEliminarPedido(item.id_pedido)} >
                                                        <Icon.XCircle />
                                                    </Button>
                                                
                                               
                                            </td>
                                        </tr>
                                    
                                    )
                                }
                            </tbody>
                        </Table>
                        :
                            <Alert style={{width: '500px'}} className="mx-auto text-center">
                                <b>No tienes pedidos. Prueba con otro rango de fechas</b>
                            </Alert>
                }
            </Row>
            </Container>
        </>
    );
}
export { PedidoCliente };
